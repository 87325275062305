import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import PositionTable from 'components/tribot/future/PositionTable';
import History from 'components/tribot/History';

import Input from '@mui/material/Input';

export default function positions() {
  const location = useLocation();
  const [data, setData] = useState([]);
  const [key, setKey] = useState<string | null>('');

  useEffect(() => {
    setKey(localStorage.getItem('tribotKey'));
  }, []);

  const onChangeKey = (e: any) => {
    localStorage.setItem('tribotKey', e.target.value);
    setKey(e.target.value);
  };

  return (
    <div className="dark:text-[#f3f3f3]">
      {key === 'variouscafe' ? (
        <div>
          <History />
          <PositionTable />
        </div>
      ) : (
        <div className="">
          key :
          <Input className="w-20" onChange={onChangeKey} value={key} />
        </div>
      )}
    </div>
  );
}
