export default new (class {
  getLastPositions = () =>
    new Promise((resolve, reject) => {
      const url = process.env.REACT_APP_API_SERVER + '/coin/tribot/upbit/positions/last';

      fetch(url)
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((error) => {
          console.log('fetch data failed', error);
        });
    });

  getTickers = () =>
    new Promise((resolve, reject) => {
      const url = process.env.REACT_APP_API_SERVER + '/coin/tribot/upbit/tickers';

      fetch(url)
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((error) => {
          console.log('fetch data failed', error);
        });
    });

  updatePositionToSell = (body) =>
    new Promise((resolve, reject) => {
      const url = process.env.REACT_APP_API_SERVER + '/coin/tribot/upbit/positions/sell';
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      }).then((res) => {
        console.log(res);
      });
    });

  createOrder = (body) =>
    new Promise((resolve, reject) => {
      const url = process.env.REACT_APP_API_SERVER + '/coin/tribot/upbit/orders';
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      }).then((res) => {
        console.log(res);
      });
    });


  getOrdersBySymbol = (symbol) =>
    new Promise((resolve, reject) => {
      const url = process.env.REACT_APP_API_SERVER + '/coin/tribot/upbit/orders?symbol='+symbol;

      fetch(url)
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((error) => {
          console.log('fetch data failed', error);
        });
    });

 getPositionsBySymbol = (symbol) =>
    new Promise((resolve, reject) => {
      const url = process.env.REACT_APP_API_SERVER + '/coin/tribot/upbit/positions?symbol='+symbol;

      fetch(url)
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((error) => {
          console.log('fetch data failed', error);
        });
    });


    

  getHistory = (symbol) =>
    new Promise((resolve, reject) => {
      const url = process.env.REACT_APP_API_SERVER + '/coin/tribot/upbit/histories';

      fetch(url)
        .then((response) => response.json())
        .then((json) => {
          // console.log(json);
          resolve(json);
        })
        .catch((error) => {
          console.log('fetch data failed', error);
        });
    });

  toFixed(num, size) {
    return Number(Number(num).toFixed(size));
  }
})();
