import { AgGridReact } from 'ag-grid-react';
import { useEffect, useState, useRef, useMemo, useCallback } from 'react';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-community/styles/ag-theme-balham.css';

import BybitClient from 'core/tribot/BybitClient';

import OrderDialog from './OrderDialog';

import SymbolRender from '../SymbolRender';
import GapRender from '../GapRender';

import './styles.css';

function profitFormatter(params:any){
  let number = Number(params.value).toFixed(1)
  return Number(number)
}

function priceFormatter(params:any){
  let number = Number(params.value).toFixed(0)
  return Number(number)
}

// function liquidationGetter(params:any){
//   let number = params.data.markPrice / params.data.liquidationPrice
//   number = Number(number.toFixed(1))
//   return number < 2 ? number : 3
// }

// function shortLiquidationGetter(params:any){
//   let number = params.data.shortLiquidationPrice / params.data.markPrice
//   number = Number(number.toFixed(1))
//   return number > 0 ? number : 3
// }




export default function PositionTableBybit() {
  const gridRef: any = useRef();

  const [rowData, setRowData] = useState([]);

    const [needOrderData, setNeedOrderData] = useState([]);
    const [needLiquidData, setNeedLiquidData] = useState([]);

  const [selectedRowData, setSelectedRowData] = useState(null);
  const [now, setNow] = useState<any>(null);

  const sortingOrder = useMemo(()=>{
    return ['desc','asc']
  },[])

  const defaultColDef = useMemo(()=>{return {sortable:true}},[])

  const [columnDefs] = useState<any>([
    { field: 'symbol', width: 85, cellRenderer: SymbolRender, sortable: true },

    { field:'profit', headerName: 'lPro', 
      width: 55, 
      cellRenderer: GapRender, 
      cellDataType:'number',
      sortingOrder:['asc','desc'],
      sort:'asc'
    },
    { field: 'shortProfit', headerName: 'sPro', 
      width: 55, 
      cellRenderer: GapRender, 
      cellDataType:'number',
      sortingOrder:['asc','desc']
    },
     {
      field: 'notional',
      headerName: 'lAmt',
      width: 60,
      valueFormatter: priceFormatter,
      cellDataType:'number',
      sortingOrder:['desc','asc'],
    },
    {
      field: 'shortNotional',
      headerName: 'sAmt',
      width: 60,
      valueFormatter: priceFormatter,
      cellDataType:'number',
      sortingOrder:['desc','asc'],
    },

    // { field: 'longPending', headerName: 'lp', width: 33, sortable: true },
    // { field: 'shortPending', headerName: 'sp', width: 35, sortable: true },

    // { field: 'longCount', headerName: 'lCnt', width: 57, sortable: true },
    // { field: 'shortCount', headerName: 'sCnt', width: 57, sortable: true },

    { field:'funding_rate', headerName: 'rate', width: 65,
      cellDataType:'number', 
      sortingOrder:['asc','desc']    
    },

    { field:'shortLiquidation', headerName: 'sLiq', width: 57,
      cellDataType:'number', 
      sortingOrder:['asc','desc']
    },
    { field:'liquidation', headerName: 'lLiq', width: 57,
      cellDataType:'number', 
      sortingOrder:['asc','desc']    
    },


    // { field: 'longAvgPrice', headerName: 'lAvg', width: 67, sortable: true },
    // { field: 'shortAvgPrice', headerName: 'sAvg', width: 67, sortable: true },

    // { field: 'longNotFilled', headerName: 'lErr', width: 57, sortable: true },
    // { field: 'shortNotFilled', headerName: 'sErr', width: 57, sortable: true },
  ]);

  useEffect(() => {
    //setRowData([]);
    asyncFetch();
  }, []);





  async function asyncFetch() {
    //const strSymbols = Symbols.join('|');
    const positions = await BybitClient.getLastPositions();
    const tickers = await BybitClient.getTickers();
     // console.log(positions);
    // console.log(tickers);

    const tempObj:any = {};

    tickers.forEach((item:any) => {

      item.isolatedMargin = Number(item.isolatedMargin)
      item.markPrice = Number(item.markPrice)
      item.liquidationPrice = Number(item.liq_price)
      item.funding_rate = item.funding_rate * 100


      const findPosition = positions.filter((position:any)=> position.symbol === item.symbol)


      if (!tempObj[item.symbol]) {
        tempObj[item.symbol] = item;
      }

      if(item.side ==='Buy'){

      	tempObj[item.symbol].notional = Math.abs(item.markPrice * item.size)


        const findLongPosition = findPosition.filter((position:any)=> position.side === 'Buy')[0]
        tempObj[item.symbol].position = findLongPosition

        if(findLongPosition){
          tempObj[item.symbol].profit = (item.markPrice / findLongPosition.buy_price - 1) * 100;
        }

        const liq = Number((item.markPrice / item.liquidationPrice).toFixed(1))
        tempObj[item.symbol].liquidation = liq < 2 ? liq : 3

      }else if(item.side ==='Sell'){
        tempObj[item.symbol].shortNotional = Math.abs(item.markPrice * item.size)
        tempObj[item.symbol].shortEntryPrice= item.entryPrice
        tempObj[item.symbol].shortLiquidationPrice= item.liquidationPrice
        tempObj[item.symbol].shortPositionAmt= item.positionAmt
        tempObj[item.symbol].shortPositionSide= item.positionSide
        tempObj[item.symbol].shortUnRealizedProfit= item.unRealizedProfit

        const findShortPosition = findPosition.filter((position:any)=> position.side === 'Sell')[0]
        tempObj[item.symbol].shortPosition = findShortPosition

        if(findShortPosition){
          tempObj[item.symbol].shortProfit = ( findShortPosition.buy_price / item.markPrice - 1) * 100;
        }
        

        const liq = Number((item.liquidationPrice / item.markPrice ).toFixed(1))
        tempObj[item.symbol].shortLiquidation = liq > 0 ? liq : 3

      }

    });

    const array:any = Object.values(tempObj)

    // console.log(array)
    setRowData(array);
    // const handledData = handleResult(result);
    const needOrder: any = array.filter((item:any) => item.profit < -3 && item.shortProfit < -3);
    setNeedOrderData(needOrder);
    const needLiquid: any = array.filter((item:any) => item.liquidation < 1.4 || item.shortLiquidation < 1.4);
    setNeedLiquidData(needLiquid);
    // gridRef.current.api.applyTransaction({ add: handledData });
  }

  const onSelectionChanged = useCallback((event: any) => {

    // console.log(event.api.getSelectedNodes())
    event.api.getSelectedNodes()[0].data.exchange = 'Bybit';
    setSelectedRowData(event.api.getSelectedNodes()[0].data);
    setNow(new Date());
  }, []);

    const orderList = needOrderData.map((item: any, index) => (
      <span className="text-xs">{item.symbol},</span>
    ));

    const liquidList = needLiquidData.map((item: any, index) => (
      <span className="text-xs">{item.symbol},</span>
    ));

  return (
    <div className="max-w-full">
      <div className="mx-2 text-xs break-words">
         <div>Order: {orderList}</div> 
         <div>Liquidation: {liquidList}</div> 
      </div>

      <div className="ag-theme-balham-dark w-full" style={{ height: '94vh' }}>
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          // sortingOrder={sortingOrder}
          rowSelection={'single'}
          onRowClicked={onSelectionChanged}
        ></AgGridReact>
      </div>

      <OrderDialog data={selectedRowData} now={now} />
    </div>
  );
}
