import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';

const colorMode = 'dark';

export const IOSSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? '#fde047' : '#fbbf24',
  height: 1,
  padding: '15px 0',
  '& .MuiSlider-thumb': {
    height: 3,
    width: 3,
    backgroundColor: '#d97706',
    // boxShadow: iOSBoxShadow,
    '&:focus, &:hover, &.Mui-active': {
      boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        // boxShadow: iOSBoxShadow,
      },
    },
  },
  '& .MuiSlider-valueLabel': {
    fontSize: 11,
    fontWeight: 'normal',
    top: 1,
    backgroundColor: 'unset',
    color: theme.palette.text.primary,
    '&:before': {
      display: 'none',
    },
    '& *': {
      background: 'transparent',
      color: colorMode === 'dark' ? '#fff' : '#000',
    },
  },
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-rail': {
    opacity: 0.5,
    backgroundColor: '#bfbfbf',
  },
  '& .MuiSlider-mark': {
    backgroundColor: '#bfbfbf',
    height: 0,
    width: 0,
    '&.MuiSlider-markActive': {
      opacity: 1,
      backgroundColor: '#9ca3af',
    },
  },
  '& .MuiSlider-markLabel': {
    fontSize: 7,
    fontWeight: 'normal',
    top: 26,
    backgroundColor: 'unset',
    color: colorMode === 'dark' ? '#d4d4d8' : '#000',
    '&:before': {
      display: 'none',
    },
    '& *': {
      background: 'transparent',
      color: colorMode === 'dark' ? '#fff' : '#000',
    },
  },
}));