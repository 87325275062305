import { Fragment, useEffect, useState } from 'react';
import { Outlet } from 'react-router';
import Input from '@mui/material/Input';

export default function SpotLayout({ children }: any) {
  const [key, setKey] = useState<string | null>('');

  useEffect(() => {
    setKey(localStorage.getItem('tribotKey'));
  }, []);

  const onChangeKey = (e: any) => {
    localStorage.setItem('tribotKey', e.target.value);
    setKey(e.target.value);
  };

  if (key !== 'variouscafe') {
    return (
      <div className="dark:text-white">
        key :
        <Input className="w-20" onChange={onChangeKey} value={key} />
      </div>
    );
  }

  return (
    <Fragment>
      {/*<BaseUiTab />*/}

      <main>{children || <Outlet />}</main>
      <div className="fixed bottom-0 z-50 bg-black w-full h-12 dark:text-gray-300">
        <div className="grid grid-cols-3 gap-x-8 my-2 mx-8 text-center">
          <div>
            <a className="p-2" href="/tribot/spot">
              TRADE
            </a>
          </div>
          <div>
            <a className="p-2" href="/tribot/spot/upbit/position">
              UPBIT
            </a>
          </div>
          <div>
            <a className="p-2" href="/tribot/spot/bithumb/position">
              BITHUMB
            </a>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
