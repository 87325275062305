import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Input from '@mui/material/Input';

function calPrice(amt:any, item:any) {
  if (item.symbol.includes('PERP')) {
    return getPerpAmt(amt, item);
  } else {
    return Number((item.markPrice * amt).toFixed(1));
  }
}

function getPerpAmt(amt:any, item:any) {
  if (item.symbol.includes('BTCUSD')) {
    return 100 * amt;
  } else {
    return 10 * amt;
  }
}

export default function EditDialog({ data, now }:any) {
  const [open, setOpen] = useState(false);

  const [amt, setAmt] = useState(null);
  const [sellPercent, setSellPercent] = useState(null);
  //   const [open, setOpen] = useState(false);

  useEffect(() => {
    console.log('EditDialog', data);
    if (data) {
      setAmt(data.amt);
      setSellPercent(data.sell_percent);
      setOpen(true);
    }
  }, [now]);

  const handleEditSave = (item:any) => {
    // setOpen(true);
    console.log(item);

    const updateModel = {
      id: item.tribot_order_id ? item.tribot_order_id : item.id,
      amt: amt,
      sell_percent: sellPercent,
    };

    // if (data.tradingType === 'SPOT') {
    //   console.log('spot update');
    // } else {
    fetch(process.env.REACT_APP_API_SERVER + `/coin/binance/tribot/orders`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updateModel),
    }).then((res) => {
      console.log(res.ok);
      setOpen(false);
    });
    // }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onChangeAmt = (e:any) => {
    setAmt(e.target.value);
  };

  const onChangeSellPercent = (e:any) => {
    setSellPercent(e.target.value);
  };

  return (
    <div>
      {data && (
        <Dialog className="" open={open} onClose={handleClose}>
          <div className="mx-4 mt-2 w-64">
            {data.side} <small>({data.id})</small>
          </div>
          <div className="mx-4 mb-1 flex items-center">
            <div className="w-24 text-xs">amt</div>
            <Input className="w-20" onChange={onChangeAmt} value={amt} />
            <div className="ml-3 text-xs">${calPrice(amt, data)}</div>
          </div>
          <div className="mx-4 flex text-gray-500">
            <div className="w-24 text-xs">order_amt</div>
            <small className="w-20">{data.order_amt}</small>
            <div className="ml-3 text-xs">${calPrice(data.order_amt, data)}</div>
          </div>

          <div className="mx-4 mb-1 flex items-center">
            <div className="w-24 text-xs">sell_percent</div>
            <Input className="w-20" onChange={onChangeSellPercent} value={sellPercent} />
          </div>

          <div className="mx-4 mt-1 mb-1 text-xs flex">
            <div className="w-24 ">position</div> {data.position}
          </div>
          <div className="mx-4 text-xs flex">
            <div className="w-24 ">buy_percent</div>
            {data.buy_percent}
          </div>

          <DialogActions>
            <Button onClick={() => handleEditSave(data)}>save</Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
