import { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import UpbitClient from 'core/tribot/upbit/client';
import BithumbClient from 'core/tribot/bithumb/client';

import EditDialog from './EditDialog';
import NewDialog from './NewDialog';

function calPrice(item:any) {
  const amt = item.amt;
  return Number((item.buy_price * amt).toFixed(0));
  
}

function getDate(mil:any){
	const time = new Date(mil)
	const date = time.getDate() < 10 ? '0'+time.getDate() : time.getDate()
	return `${date}/${time.getMonth()+1}/${time.getFullYear().toString().substring(2,4)}`
}


function getPositionCount(data:any, side:string){
	return data.length
}


function getOrderCount(data:any, side:string){
	return data.length
}

export default function OrderDialog({ data, now }:any) {
  const [open, setOpen] = useState(false);
  const [selectedEdit, setSelectedEdit] = useState(null);
  const [selectedSide, setSelectedSide] = useState(null);

  const [nowDate, setNowDate] = useState<any>(null);
  const [newNowDate, setNewNowDate] = useState<any>(null);

  const [positions, setPositions] = useState([])
  const [orders, setOrders] = useState([])
  const [lastOrder, setLastOrder] = useState({created_date:''})
  const [amtObj, setAmtObj] = useState(0)

  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (data) {
      setOpen(true);
      asyncFetch()
    }
  }, [now]);


  async function asyncFetch() {


  	console.log(data.exchange)

  	const orders = data.exchange === 'Upbit' ? await UpbitClient.getOrdersBySymbol(data.symbol) : await BithumbClient.getOrdersBySymbol(data.symbol) 
  	const positions = data.exchange === 'Upbit' ? await UpbitClient.getPositionsBySymbol(data.symbol) : await BithumbClient.getPositionsBySymbol(data.symbol)


    console.log(orders)
    console.log('positions',positions)

  	setOrders(orders)
  	setPositions(positions)


  	let lastOrder:any = {}

  	orders.forEach((item:any, key:any) => {
  			if(!lastOrder.id){
  				lastOrder = item
  			}
  			if(item.id > lastOrder.id){
  				lastOrder = item
  			}
  		
  	})

  	console.log('lastOrder',lastOrder)
  	setLastOrder(lastOrder)

    let longAmtSum = 0

  	positions.forEach((item:any, key:any) => {
  			longAmtSum+= item.amt
  	})

  	  setAmtObj(Number(longAmtSum.toFixed(0)))

  }


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    data = null;
    setOpen(false);
  };

  function handleEdit(item:any) {
    console.log(item);
    item.markPrice = data.markPrice;
    setSelectedEdit(item);
    setNowDate(new Date());
  }

  function handleNew() {
    setNewNowDate(new Date());
  }


  function showList() {
    let result = [];





    const reverses = positions.sort(function (a:any, b:any) {
        return a.buy_price - b.buy_price;
    });


    result.push(
      <div className="py-1 flex items-center text-xs">
        <span className="w-7"></span>
        <span className="mx-1.5 w-12">price</span>
        <span className="ml-4 w-3">s.p</span>
        <span className="mx-4 w-16">amt</span>
        <span className="mx-3.5 w-8">date</span>
      </div>
    );

    result.push(
          <div className="py-1 flex items-center" >
            <span
              className={`w-7 text-center rounded-lg text-[6px] bg-yellow-500/60`}
            >
              mark
            </span>
            <span className="mx-2.5 w-full text-xs">{data.markPrice}</span>
          </div>
        );

    reverses.forEach((item:any, key:any) => {
        result.push(
          <div className="py-1 flex items-center" key={key}>
            <span
              className={`w-7 text-center rounded-lg text-[6px] bg-green-500/20`}
            >
            </span>
            <span className="mx-2 w-14 text-xs">{item.buy_price}</span>
            <span className="mx-2 w-2 text-xs">{item.sell_percent}</span>
            <span className="mx-2 w-20 text-xs">
            	{item.amt.toFixed(1)}
              <small className="text-gray-200"> ({calPrice(item)})</small>
            </span>
            <span className="mx-2 w-14 text-xs">{getDate(item.buy_created_date)}</span>
          </div>
        );

    });



  


    return <div className="h-[60vh] overflow-y-auto">{result}</div>;
  }

  return (
    <div className="">
      {data && (
        <Dialog fullScreen={fullScreen} className="pr-28" open={open} onClose={handleClose}>
          <div></div>
          <div className="m-3 flex justify-between ">{data.symbol} / {data.markPrice}</div>

          <div className="px-2 grid grid-cols-2 gap-1 text-sm text-center">
            <div></div>
            <div className="text-xs text-green-700 font-bold">Long</div>
            <div className="text-xs pt-1.5 border-t border-gray-700 text-left">Total</div>
            <div className="pt-1.5 border-t border-gray-700">
              {amtObj * data.markPrice} <small>({amtObj})</small>
            </div>

            <div className="text-xs pt-1.5 border-t border-gray-700 text-left">Profit</div>
            <div className="pt-1.5 border-t border-gray-700 ">{data.profit?.toFixed(1)}</div>

            <div className="text-xs pt-1.5 border-t border-gray-700 text-left">Order/Position</div>
            <div className="pt-1.5 border-t border-gray-700">{getOrderCount(orders,'LONG')} / {getPositionCount(positions,'LONG')}</div>

            <div className="text-xs pt-1.5 border-t border-gray-700 text-left">OrderDate</div>
            <div className="pt-1.5 border-t border-gray-700">{getDate(lastOrder.created_date)}</div>
          </div>

          <div className="my-4 flex justify-center">
            <Button variant="outlined" onClick={() => handleNew()}>
              <span className="text-xs text-green-700">New Long</span>
            </Button>


          </div>
  				
  			<div className="mx-2 divide-y divide-gray-700">{showList()}</div>
    
  	    </Dialog>
      )}

      <EditDialog data={selectedEdit} now={nowDate} />
      <NewDialog data={data} now={newNowDate} />
    </div>
  );
}
