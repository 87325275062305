import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Input from '@mui/material/Input';

function calPrice(amt:any, item:any) {

	return Number((item.markPrice * amt).toFixed(1));
}


export default function NewDialog({ data, now }:any) {
	const [open, setOpen] = useState(false);
	const [amt, setAmt] = useState(0);
	const [sellPercent, setSellPercent] = useState(0);


	console.log(data)

	useEffect(() => {
		if(data){

			if(data.exchange === 'Upbit'){
				setAmt(data.price);
			}else{
				setAmt(data.amt);
			}

			setSellPercent(data.sell_percent ? data.sell_percent : 3);
			setOpen(true);
		}
	}, [now]);

	const handleSave = (item:any) => {

		let newModel:any = {
			symbol: item.symbol,
			amt: amt,
			sell_percent: sellPercent,
		};

		let url:any = process.env.REACT_APP_API_SERVER;

		if(item.exchange === 'Upbit'){
			url += '/coin/tribot/upbit/orders'
		}else if(item.exchange === 'Bithumb'){
			url += '/coin/tribot/bithumb/orders'
		}

		console.log(newModel)

		fetch(url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(newModel),
		}).then((res) => {
			setOpen(false);
			console.log(res);
		});
	};

	const handleClose = () => {
		setOpen(false);
	};

	const onChangeAmt = (e:any) => {
		setAmt(e.target.value);
	};

	const onChangeSellPercent = (e:any) => {
		setSellPercent(e.target.value);
	};

	return (
		<div>
			{data && (
				<Dialog className="" open={open} onClose={handleClose}>
					<div className="mx-4 mt-2 w-64">{data.symbol} <small>({data.markPrice})</small></div>
					<div className="mx-4 mt-3 flex items-center">
						<div className="w-24 text-xs">amt</div>
						<Input className="w-20" onChange={onChangeAmt} value={amt} />
						{data.exchange !== 'Upbit' && <div className="ml-3 text-xs">{calPrice(amt, data)}</div>}
						
					</div>

					<div className="mx-4 my-1 flex items-center">
						<div className="w-24 text-xs">sell_percent</div>
						<Input className="w-20" onChange={onChangeSellPercent} value={sellPercent} />
					</div>


					<DialogActions>
						<Button onClick={() => handleSave(data)}>add </Button>
					</DialogActions>
				</Dialog>
			)}
		</div>
	);
}
