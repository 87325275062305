import { useEffect, useState } from 'react';
import BithumbClient from 'core/tribot/bithumb/client';
import UpbitClient from 'core/tribot/upbit/client';

import { IOSSlider } from 'components/tribot/PositionSlider';

function getMarks(item: any) {
  const marks = [];

  if (item.plusPercent > 0.4) {
    marks.push({ value: 0, label: item.buy_price });
    marks.push({ value: item.profit, label: item.markPrice });
    if (item.plusPercent < 0.8) {
      marks.push({ value: item.sellPercent, label: item.sellPercent });
    }
  } else {
    marks.push({ value: item.sellPercent, label: item.sellPercent });
  }

  return marks;
}

function showExchangeChip(symbol: string) {
  if (symbol?.includes('KRW-')) {
    return (
      <div className="">
        <div className="rounded-full bg-blue-500 opacity-90 px-1 text-white font-semibold text-[7px]">
          U
        </div>
      </div>
    );
  } else {
    return (
      <div className="">
        <div className="rounded-full bg-amber-500 opacity-90 px-1 text-white font-semibold text-[7px]">
          B
        </div>
      </div>
    );
  }
}

function getTime(time: string) {
  const newDate = new Date(time);
  return newDate.getHours() + ':' + newDate.getMinutes() + ' , ' + newDate.getDate();
}

export default function UpbitPosition() {
  // const [ticker, setTicker] = useState<{} | null>(null);
  const [displayPositions, setDisplayPositions] = useState([]);
  const [history, setHistory] = useState<any>([]);
  const [historySum, setHistorySum] = useState({ spot: '', perp: '' });
  const [btc, setBtc] = useState('');

  useEffect(() => {
    getPositions();
    getHistory(UpbitClient);
    let historyInterval = 0;
    const timer = setInterval(() => {
      historyInterval++;

      getPositions();

      if (historyInterval === 1) {
        getHistory(UpbitClient);
      } else if (historyInterval === 2) {
      } else if (historyInterval === 3) {
        getHistory(BithumbClient);
      } else if (historyInterval > 4) {
        historyInterval = 0;
      }
    }, 2000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  async function getPositions() {
    const upbitLastPositions = await UpbitClient.getLastPositions();
    const upbitTickers = await UpbitClient.getTickers();
    const upbitDisplayPositions = getDisplayPositions(upbitLastPositions, upbitTickers);

    const bithumbLastPositions = await BithumbClient.getLastPositions();
    const bithumbTickers = await BithumbClient.getTickers();
    const bithumbDisplayPositions = getDisplayPositions(bithumbLastPositions, bithumbTickers);

    // console.log(bithumbLastPositions)
    // console.log(bithumbTickers);

    const tempDisplayPositions: any = [...upbitDisplayPositions, ...bithumbDisplayPositions];

    tempDisplayPositions.sort(function (a: any, b: any) {
      return b.profit - a.profit;
    });

    setDisplayPositions(tempDisplayPositions);
  }

  function getDisplayPositions(positions: any, tickers: any) {
    const tempObj: any = {};
    tickers.forEach((item: any) => {
      tempObj[item.symbol] = item;
    });

    positions.forEach((item: any) => {
      // if (item.exchange === 'Binance' && item.symbol === 'BTCUSDT') {
      //   setBtc(tempObj[item.symbol].markPrice.split('.')[0]);
      // }

      if (tempObj[item.symbol]) {
        item.markPrice = Number(tempObj[item.symbol].markPrice);

        const profit = (item.markPrice / item.buy_price - 1) * 100;
        item.profit = item.buy_price ? Number(profit.toFixed(2)) : -1;

        item.sellPercent = item.sell_percent;
        item.plusPercent = item.profit / item.sellPercent;
      }
    });

    return positions.filter((item: any) => item.profit > -1);
  }

  async function getHistory(client: any) {
    const result = await client.getHistory();

    let spotSum = 0;
    let perpSum = 0;

    result.forEach((item: any) => {
      item.profit = item.amt * (item.sell_price - item.buy_price);
      spotSum += item.profit;
      item.profit = item.profit.toFixed(0);
    });

    result.sort(function (a: any, b: any) {
      try {
        return b.id - a.id;
      } catch (e) {}
    });

    setHistorySum({ spot: spotSum.toFixed(0), perp: perpSum.toFixed(0) });
    setHistory(result);
  }

  function clickSell(item: any) {
    const newModel = {
      id: item.id,
    };

    if (item.symbol.includes('KRW-')) {
      UpbitClient.updatePositionToSell(newModel);
    } else {
      BithumbClient.updatePositionToSell(newModel);
    }
  }

  const historyList = history.map((item: any, index: number) => (
    <div key={item.id} className="flex items-center mt-0.5">
      <span className="text-[8px] w-5">{index + 1}</span>
      <div className="text-[10px] ml-1 w-6 font-semibold">{item.profit}</div>
      <div className="text-[10px] ml-3 w-14">{getTime(item.created_date)}</div>
      <div className="text-[10px] mr-2">{item.symbol}</div>
    </div>
  ));

  const positionList = displayPositions.map((item: any) => (
    <div key={item.id} className="flex items-start">
      <div className="w-28 flex items-center">
        {showExchangeChip(item.symbol)}
        <div className="ml-0.5 text-[10px] font-semibold">{item.symbol}</div>
      </div>
      <div className="w-24">
        {item.profit && (
          <IOSSlider
            key={item.symbol}
            size="small"
            value={item.profit}
            max={item.profit > 0 ? item.sellPercent : 0}
            min={item.profit > 0 ? 0 : item.profit}
            aria-label="Small"
            valueLabelDisplay="on"
            marks={getMarks(item)}
          />
        )}
      </div>
      <div>
        {item.symbol?.includes('KRW-') && item.profit >= 0.9 && item.sellPercent > 1 && (
          <button
            className="ml-3 my-0.5 py-0.5 px-2 border border-gray-300 rounded-md flex items-center"
            onClick={() => clickSell(item)}
          >
            <span className="text-xs">sell</span>
          </button>
        )}

        {!item.symbol?.includes('KRW-') && item.profit >= 0.4 && item.sellPercent > 0.5 && (
          <button
            className="ml-3 my-0.5 py-0.5 px-2 border border-gray-300 rounded-md flex items-center"
            onClick={() => clickSell(item)}
          >
            <span className="text-xs">sell</span>
          </button>
        )}
      </div>
    </div>
  ));

  return (
    <div className="pt-3">
      <div className="grid grid-cols-2 dark:text-[#f3f3f3] md:justify-center">
        <div className="ml-2">
          <div className="mb-5 text-lg font-semibold">{btc}</div>
          {positionList}
        </div>
        <div className="ml-4">
          <div className="flex items-center">
            <div className="w-3 mr-1">{showExchangeChip(history[0]?.symbol)}</div>
            <div>{history[0]?.symbol.includes('KRW-') ? 'Upbit' : 'Bithumb'} : </div>
            <div className="ml-2">{historySum.spot}</div>
          </div>
          <div className="">{historyList}</div>
        </div>
      </div>
    </div>
  );
}
