export default new (class {
	getLastPositions = () =>
		new Promise((resolve, reject) => {
			const url = process.env.REACT_APP_API_SERVER + '/coin/tribot/bithumb/positions/last';

			fetch(url)
				.then((response) => response.json())
				.then((json) => {
					resolve(json);
				})
				.catch((error) => {
					console.log('fetch data failed', error);
				});
		});

	getTickers = () =>
		new Promise((resolve, reject) => {
			const url = process.env.REACT_APP_API_SERVER + '/coin/tribot/bithumb/tickers';

			fetch(url)
				.then((response) => response.json())
				.then((json) => {
					resolve(json);
				})
				.catch((error) => {
					console.log('fetch data failed', error);
				});
		});


  updatePositionToSell = (body) =>
    new Promise((resolve, reject) => {
      const url = process.env.REACT_APP_API_SERVER + '/coin/tribot/bithumb/positions/sell';
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      }).then((res) => {
        console.log(res);
      });
    });


  createOrder = (body) =>
    new Promise((resolve, reject) => {
      const url = process.env.REACT_APP_API_SERVER + '/coin/tribot/bithumb/orders';
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      }).then((res) => {
        console.log(res);
      });
    });


  getOrdersBySymbol = (symbol) =>
    new Promise((resolve, reject) => {
      const url = process.env.REACT_APP_API_SERVER + '/coin/tribot/bithumb/orders?symbol='+symbol;

      fetch(url)
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((error) => {
          console.log('fetch data failed', error);
        });
    });

 getPositionsBySymbol = (symbol) =>
    new Promise((resolve, reject) => {
      const url = process.env.REACT_APP_API_SERVER + '/coin/tribot/bithumb/positions?symbol='+symbol;

      fetch(url)
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((error) => {
          console.log('fetch data failed', error);
        });
    });




	getHistory = (symbol) =>
		new Promise((resolve, reject) => {
			const url = process.env.REACT_APP_API_SERVER + '/coin/tribot/bithumb/histories';

			fetch(url)
				.then((response) => response.json())
				.then((json) => {
					// console.log(json);
					resolve(json);
				})
				.catch((error) => {
					console.log('fetch data failed', error);
				});
		});

	toFixed(num, size) {
		return Number(Number(num).toFixed(size));
	}
})();
