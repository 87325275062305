import { useEffect, useState } from 'react';
import Client from 'core/tribot/client';

function getProfit(item) {
  let amt = Client.getPerpAmt(item.symbol, item.amt);
  if (item.position === 'LONG') {
    const profitPercent = item.sell_price / item.buy_price - 1;

    if (item.symbol.includes('PERP')) {
      return profitPercent * amt;
    } else {
      return profitPercent * amt * item.buy_price;
    }
  } else if (item.position === 'SHORT') {
    const profitPercent = item.buy_price / item.sell_price - 1;

    if (item.symbol.includes('PERP')) {
      return profitPercent * amt;
    } else {
      //   console.log(item);
      return profitPercent * amt * item.buy_price;
    }
  }
}

export default function History() {
  const [history, setHistory] = useState({});

  useEffect(() => {
    asyncFetch();
  }, []);

  async function asyncFetch() {
    const result = await Client.getHistory();

    const tempHistory = {};
    tempHistory.longProfit = 0;
    tempHistory.shortProfit = 0;
    tempHistory.total = 0;

    result.forEach((item) => {
      if (item.position === 'LONG') {
        tempHistory.longProfit += getProfit(item);
      } else {
        tempHistory.shortProfit += getProfit(item);
      }
    });

    tempHistory.longProfit = Client.toFixed(tempHistory.longProfit, 1);
    tempHistory.shortProfit = Client.toFixed(tempHistory.shortProfit, 1);

    tempHistory.total = tempHistory.longProfit + tempHistory.shortProfit;
    tempHistory.total = Client.toFixed(tempHistory.total, 1);

    setHistory(tempHistory);
  }

  return (
    <div className="mx-2 text-xs">
      <div>
        Total : {history.total} ({history.longProfit} / {history.shortProfit})
      </div>
    </div>
  );
}
