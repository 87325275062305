import { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import BinanceClient from 'core/tribot/BinanceClient';
import BybitClient from 'core/tribot/BybitClient';

import EditDialog from './EditDialog';
import NewDialog from './NewDialog';

function calPrice(item:any) {
  const amt = item.amt;

  if (item.symbol.includes('PERP')) {
    return getPerpAmt(item);
  } else {
    return Number((item.buy_price * amt).toFixed(0));
  }
}

function getPerpAmt(item:any) {
  if (item.symbol.includes('BTCUSD')) {
    return 100 * item.amt;
  } else {
    return 10 * item.amt;
  }
}

function getDate(mil:any){
	const time = new Date(mil)
	const date = time.getDate() < 10 ? '0'+time.getDate() : time.getDate()
	return `${date}/${time.getMonth()+1}/${time.getFullYear().toString().substring(2,4)}`
}


function getPositionCount(data:any, side:string){
	const bybitSide = side === 'LONG' ? 'Buy' : 'Sell'
	return data.filter((item:any)=> item.position_side === side || item.side === bybitSide).length
}


function getOrderCount(data:any, side:string){
	const bybitSide = side === 'LONG' ? 'Buy' : 'Sell'
	return data.filter((item:any)=> item.position === side || item.side === bybitSide).length
}

export default function OrderDialog({ data, now }:any) {
  const [open, setOpen] = useState(false);
  const [selectedEdit, setSelectedEdit] = useState(null);
  const [selectedSide, setSelectedSide] = useState(null);

  const [nowDate, setNowDate] = useState<any>(null);
  const [newNowDate, setNewNowDate] = useState<any>(null);

  const [positions, setPositions] = useState([])
  const [orders, setOrders] = useState([])
  const [lastOrder, setLastOrder] = useState({long:{created_date:''},short:{created_date:''}})
  const [amtObj, setAmtObj] = useState({long:'0',short:'0'})

  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (data) {
      setOpen(true);
      asyncFetch()
    }
  }, [now]);


  async function asyncFetch() {


  	console.log(data.exchange)

  	const orders = data.exchange === 'Binance' ? await BinanceClient.getOrdersBySymbol(data.symbol) : await BybitClient.getOrdersBySymbol(data.symbol) 
  	const positions = data.exchange === 'Binance' ? await BinanceClient.getPositionsBySymbol(data.symbol) : await BybitClient.getPositionsBySymbol(data.symbol)

  	setOrders(orders)
  	setPositions(positions)


  	const lastOrder:any = {long:{},short:{}}

  	orders.forEach((item:any, key:any) => {
  		if (item.position === 'LONG' || item.side === 'Buy') {
  			if(!lastOrder.long.id){
  				lastOrder.long = item
  			}
  			if(item.id > lastOrder.long.id){
  				lastOrder.long = item
  			}
  		}else if(item.position === 'SHORT' || item.side === 'Sell'){
  			if(!lastOrder.short.id){
  				lastOrder.short = item
  			}
  			if(item.id > lastOrder.short.id){
  				lastOrder.short = item
  			}
  		}
  	})

  	console.log(lastOrder)

  	setLastOrder(lastOrder)


    let longAmtSum = 0
    let shortAmtSum = 0

  	positions.forEach((item:any, key:any) => {
  		if (item.position_side === 'LONG' || item.side === 'Buy') {
  			longAmtSum+= item.amt
  		}else if(item.position_side === 'SHORT' || item.side === 'Sell'){
  			shortAmtSum+=item.amt
  		}
  	})

  	  setAmtObj({long:longAmtSum.toFixed(0),short:shortAmtSum.toFixed(0)})


  	// console.log(orders)
  	// console.log(positions)
  }


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    data = null;
    setOpen(false);
  };

  function handleEdit(item:any) {
    console.log(item);
    item.markPrice = data.markPrice;
    setSelectedEdit(item);
    setNowDate(new Date());
  }

  function handleNew(position:any) {
  	setSelectedSide(position)
    setNewNowDate(new Date());
  }


  function showList() {
    let result = [];

    result.push(
      <div className="py-1 flex items-center text-xs">
        <span className="w-7"></span>
        <span className="mx-1.5 w-12">price</span>
        <span className="ml-3 w-3">s.p</span>
        <span className="mx-3.5 w-12">amt</span>
        <span className="mx-3.5 w-8">date</span>
      </div>
    );

    positions.forEach((item:any, key:any) => {
      if (item.position_side === 'LONG' || item.side === 'Buy') {
        result.push(
          <div className="py-1 flex items-center" key={key}>
            <span
              className={`w-7 text-center rounded-lg text-[6px] bg-green-500/20`}
            >
              {item.side}
            </span>
            <span className="mx-2 w-14 text-xs">{item.buy_price}</span>
            <span className="mx-2 w-2 text-xs">{item.sell_percent}</span>
            <span className="mx-2 w-16 text-xs">
            	{item.amt}
              <small className="text-gray-200"> (${calPrice(item)})</small>
            </span>
            <span className="mx-2 w-14 text-xs">{getDate(item.buy_created_date)}</span>
          </div>
        );
      }

    });



		result.push(
          <div className="py-1 flex items-center" >
            <span
              className={`w-7 text-center rounded-lg text-[6px] bg-yellow-500/60`}
            >
              mark
            </span>
            <span className="mx-2.5 w-full text-xs">{data.markPrice}</span>
          </div>
        );

    const reverses = positions.sort(function (a:any, b:any) {
        return b.buy_price - a.buy_price;
    });

    reverses.forEach((item:any, key:any) => {
      if (item.position_side === 'SHORT' || item.side === 'Sell') {
        result.push(
          <div className="py-1 flex items-center" key={key}>
            <span
              className={`w-7 text-center rounded-lg text-[6px] bg-red-500/20`}
            >
              {item.side}
            </span>
            <span className="mx-2 w-14 text-xs">{item.buy_price}</span>
            <span className="mx-2 w-2 text-xs">{item.sell_percent}</span>
            <span className="mx-2 w-16 text-xs">
            	{item.amt}
              <small className="text-gray-200"> (${calPrice(item)})</small>
            </span>
            <span className="mx-2 w-14 text-xs">{getDate(item.buy_created_date)}</span>
          </div>
        );
      }
    });


    return <div className="h-[60vh] overflow-y-auto">{result}</div>;
  }

  return (
    <div className="">
      {data && (
        <Dialog fullScreen={fullScreen} className="pr-28" open={open} onClose={handleClose}>
          <div></div>
          <div className="m-3 flex justify-between ">{data.symbol} / {data.markPrice}</div>

          <div className="px-2 grid grid-cols-3 gap-1 text-sm text-center">
            <div></div>
            <div className="text-xs text-green-700 font-bold">Long</div>
            <div className="text-xs text-red-700 font-bold">Short</div>
            <div className="text-xs pt-1.5 border-t border-gray-700 text-left">Total $</div>
            <div className="pt-1.5 border-t border-gray-700">
              {data.notional.toFixed(0)} <small>({amtObj.long})</small>
            </div>
            <div className="pt-1.5 border-t border-gray-700">
              {data.shortNotional.toFixed(0)} <small>({amtObj.short})</small>
            </div>

            <div className="text-xs pt-1.5 border-t border-gray-700 text-left">Profit</div>
            <div className="pt-1.5 border-t border-gray-700 ">{data.profit?.toFixed(1)}</div>
            <div className="pt-1.5 border-t border-gray-700">{data.shortProfit?.toFixed(1)}</div>

            <div className="text-xs pt-1.5 border-t border-gray-700 text-left">Liqui</div>
            <div className="pt-1.5 border-t border-gray-700">
              <span
                className={`px-2 text-center rounded-lg text-xs ${
                  data.liquidation > 1.6 ? 'bg-green-500/10' : 'bg-red-500/20'
                }`}
              >
                {data.liquidation}
              </span>
            </div>
            <div className="pt-1.5 border-t border-gray-700">
              <span
                className={`px-2 text-center rounded-lg text-xs ${
                  data.shortLiquidation > 1.6 ? 'bg-green-500/10' : 'bg-red-500/20'
                }`}
              >
                {data.shortLiquidation}
              </span>
            </div>


            <div className="text-xs pt-1.5 border-t border-gray-700 text-left">O/P</div>
            <div className="pt-1.5 border-t border-gray-700">{getOrderCount(orders,'LONG')} / {getPositionCount(positions,'LONG')}</div>
            <div className="pt-1.5 border-t border-gray-700">{getOrderCount(orders,'SHORT')} / {getPositionCount(positions,'SHORT')}</div>

            <div className="text-xs pt-1.5 border-t border-gray-700 text-left">Date</div>
            <div className="pt-1.5 border-t border-gray-700">{getDate(lastOrder.long.created_date)}</div>
            <div className="pt-1.5 border-t border-gray-700">{getDate(lastOrder.short.created_date)}</div>


          </div>



          <div className="my-4 flex justify-center">
            <Button variant="outlined" onClick={() => handleNew('LONG')}>
              <span className="text-xs text-green-700">New Long</span>
            </Button>

            <div className="ml-3">
              <Button variant="outlined" onClick={() => handleNew('SHORT')}>
                <span className="text-xs text-red-700">New Short</span>
              </Button>
            </div>
          </div>
  				
  			<div className="mx-2 divide-y divide-gray-700">{showList()}</div>
    
  	    </Dialog>
      )}

      <EditDialog data={selectedEdit} now={nowDate} />
      <NewDialog data={data} side={selectedSide} now={newNowDate} />
    </div>
  );
}
