import { useEffect, useState } from 'react';
import BybitClient from 'core/tribot/BybitClient';
import BinanceClient from 'core/tribot/BinanceClient';

import { IOSSlider } from 'components/tribot/PositionSlider';

function getMarks(item: any) {
  const marks = [];

  if (item.plusPercent > 0.4) {
    marks.push({ value: 0, label: item.buy_price });
    marks.push({ value: item.profit, label: item.markPrice });
    if (item.plusPercent < 0.8) {
      marks.push({ value: item.sellPercent, label: item.sellPercent });
    }
  } else {
    marks.push({ value: item.sellPercent, label: item.sellPercent });
  }

  return marks;
}

function showPositionChip(item: any) {
  let side = '';

  if (item.position_side) {
    side = item.position_side;
  } else if (item.position) {
    side = item.position;
  } else {
    side = item.side;
  }

  const firstString = side.substring(0, 1);
  if (firstString === 'B' || firstString === 'L') {
    return (
      <div className="">
        <div className="rounded-full bg-lime-500 opacity-80 px-1 text-white font-semibold text-[7px]">
          {firstString}
        </div>
      </div>
    );
  } else {
    return (
      <div className="">
        <div className="rounded-full bg-orange-500 opacity-80 px-1 text-white font-semibold text-[7px]">
          {firstString}
        </div>
      </div>
    );
  }
}

function showExchangeChip(exchange: string) {
  if (exchange === 'Bybit') {
    return (
      <div className="">
        <div className="rounded-full bg-gray-500 opacity-90 px-1 text-white font-semibold text-[7px]">
          y
        </div>
      </div>
    );
  } else {
    return (
      <div className="">
        <div className="rounded-full bg-yellow-500 opacity-90 px-1 text-white font-semibold text-[7px]">
          n
        </div>
      </div>
    );
  }
}

function getTime(time: string) {
  const newDate = new Date(time);
  return newDate.getHours() + ':' + newDate.getMinutes() + ' , ' + newDate.getDate();
}

export default function UpbitPosition() {
  // const [ticker, setTicker] = useState<{} | null>(null);
  const [displayPositions, setDisplayPositions] = useState([]);
  const [history, setHistory] = useState<any>([]);
  const [historySum, setHistorySum] = useState({spot:'',perp:''})
  const [btc, setBtc] = useState('')

  useEffect(() => {
    getPositions();
    getHistory(BinanceClient);
    let historyInterval = 0;
    const timer = setInterval(() => {
      historyInterval++;

      getPositions();


      if (historyInterval === 1) {
        // getPositions(1, BinanceClient);
        getHistory(BinanceClient);
      } else if (historyInterval === 2) {
      } else if (historyInterval === 3) {
        // getPositions(2, BybitClient);
        getHistory(BybitClient);
      } else if (historyInterval > 4) {
        historyInterval = 0;
      }
    }, 2000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  async function getPositions() {
    const binanceLastPositions = await BinanceClient.getLastPositions();
    const binanceTickers = await BinanceClient.getTickers();
    const binanceDisplayPositions = getDisplayPositions(binanceLastPositions, binanceTickers);

    const bybitLastPositions = await BybitClient.getLastPositions();
    const bybitTickers = await BybitClient.getTickers();
    const bybitDisplayPositions = getDisplayPositions(bybitLastPositions, bybitTickers);

    // console.log(bybitLastPositions)
    console.log(bybitTickers)

    const tempDisplayPositions :any = [...binanceDisplayPositions, ...bybitDisplayPositions]

    tempDisplayPositions.sort(function (a: any, b: any) {
      return b.profit - a.profit;
    });

    setDisplayPositions(tempDisplayPositions);
  }

  function getDisplayPositions(positions: any, tickers:any) {

    const tempObj: any = {};
    tickers.forEach((item: any) => {
      tempObj[item.symbol] = item;
    });

    positions.forEach((item: any) => {

      if(item.exchange === 'Binance' && item.symbol === 'BTCUSDT'){
        setBtc(tempObj[item.symbol].markPrice.split('.')[0])
      }

      if (tempObj[item.symbol]) {
        item.markPrice = Number(tempObj[item.symbol].markPrice);

        if (item.side === 'Buy' || item.position_side === 'LONG') {
          const profit = (item.markPrice / item.buy_price - 1) * 100;
          item.profit = item.buy_price ? Number(profit.toFixed(2)) : -1;

          item.sellPercent = item.sell_percent;
          item.plusPercent = item.profit / item.sellPercent;
        } else if (item.side === 'Sell' || item.position_side === 'SHORT') {
          const profit = (item.buy_price / item.markPrice - 1) * 100;
          item.profit = item.buy_price ? Number(profit.toFixed(2)) : -1;
          item.sellPercent = item.sell_percent;
          item.plusPercent = item.profit / item.sellPercent;
        }
      }
    });

    return positions.filter((item: any) => item.profit > 0);
  }

  async function getHistory(client: any) {
    const result = await client.getHistory();

    let spotSum = 0
    let perpSum = 0

    result.forEach((item: any) => {

      if (item.symbol.includes('_PERP')) {
        if (item.symbol.includes('BTCUSD')) {
          item.amt = 100 * item.amt / item.buy_price;
        } else {
          item.amt =  10 * item.amt/ item.buy_price;
        }

        if (item.position === 'LONG' || item.side === 'Buy') {
          item.profit = item.amt * (item.sell_price - item.buy_price);
        } else if (item.position === 'SHORT' || item.side === 'Sell') {
          item.profit = item.amt * (item.buy_price - item.sell_price);
        }

        perpSum += item.profit
      }else{

        if (item.position === 'LONG' || item.side === 'Buy') {
          item.profit = item.amt * (item.sell_price - item.buy_price);
        } else if (item.position === 'SHORT' || item.side === 'Sell') {
          item.profit = item.amt * (item.buy_price - item.sell_price);
        }
        spotSum += item.profit

      }

      item.profit = item.profit.toFixed(2);
    });


    result.sort(function (a: any, b: any) {
      try {
        return b.id - a.id;
      } catch (e) {}
    });

    setHistorySum({spot:spotSum.toFixed(0), perp: perpSum.toFixed(0)})
    setHistory(result);
  }

  function clickSell(item: any) {
    const newModel = {
      id: item.id,
    };

    if (item.exchange === 'Bybit') {
      BybitClient.updatePositionToSell(newModel);
    } else if (item.exchange === 'Binance') {
      BinanceClient.updatePositionToSell(newModel);
    }
  }

  const historyList = history.map((item: any, index:number) => (
    <div key={item.id} className="flex items-center mt-0.5">
      <span className="text-[8px] w-5">{index + 1}</span>
      {showPositionChip(item)}
      <div className="text-[10px] ml-1 w-6 font-semibold">{item.profit}</div>
      <div className="text-[10px] ml-3 w-14">{getTime(item.created_date)}</div>
      <div className="text-[10px] mr-2">{item.symbol}</div>
    </div>
  ));

  const positionList = displayPositions.map((item: any) => (
    <div key={item.id} className="flex items-start">
      <div className="w-28 flex items-center">
        {showExchangeChip(item.exchange)}
        {showPositionChip(item)}
        <div className="ml-0.5 text-[10px] font-semibold">{item.symbol}</div>
      </div>
      <div className="w-24">
        {item.profit && (
          <IOSSlider
            key={item.symbol}
            size="small"
            value={item.profit}
            max={item.profit > 0 ? item.sellPercent : 0}
            min={item.profit > 0 ? 0 : item.profit}
            aria-label="Small"
            valueLabelDisplay="on"
            marks={getMarks(item)}
          />
        )}
      </div>
      <div>
        {item.profit >= 0.9 && item.sellPercent !== 1 && (
          <button
            className="ml-3 my-0.5 py-0.5 px-2 border border-gray-300 rounded-md flex items-center"
            onClick={() => clickSell(item)}
          >
            <span className="text-xs">sell</span>
          </button>
        )}
      </div>
    </div>
  ));

  return (
    <div className="pt-3">
      <div className="grid grid-cols-2 dark:text-[#f3f3f3] md:justify-center">
        <div className="ml-2">
          <div className="mb-5 text-lg font-semibold">{btc}</div>
          {positionList}
        </div>
        <div className="ml-4">
          <div>{history[0]?.side ? 'Bybit' : 'Binance'} : {historySum.spot}, {historySum.perp}</div>
          <div className="">{historyList}</div>
        </div>
      </div>
    </div>
  );
}
