import { AgGridReact } from 'ag-grid-react';
import { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-community/styles/ag-theme-balham.css';

import UpbitClient from 'core/tribot/upbit/client';
import BithumbClient from 'core/tribot/bithumb/client';

import OrderDialog from './OrderDialog';

import SymbolRender from '../SymbolRender';
import GapRender from '../GapRender';

import './styles.css';

function profitFormatter(params:any){
  let number = Number(params.value).toFixed(1)
  return Number(number)
}

function priceFormatter(params:any){
  let number = Number(params.value).toFixed(0)
  return Number(number)
}


function dateFormatter(params:any){
  return params.value.split('T')[0]  
}



function getClient(pathname: string) {
  if (pathname.includes('/upbit')) {
    return UpbitClient;
  } else if (pathname.includes('/bithumb')) {
    return BithumbClient;
  } else {
    return UpbitClient;
  }
}

function getExchange(pathname: string) {
  if (pathname.includes('/upbit')) {
    return 'Upbit';
  } else if (pathname.includes('/bithumb')) {
    return 'Bithumb';
  } else {
    return '';
  }
}


export default function PositionTableUpbit() {
  const location = useLocation();

  const gridRef: any = useRef();

  const [rowData, setRowData] = useState([]);
  const [needOrderData, setNeedOrderData] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [now, setNow] = useState<any>(null);



  const client: any = getClient(location.pathname);
  const exchange = getExchange(location.pathname)


  const sortingOrder = useMemo(()=>{
    return ['desc','asc']
  },[])

  const defaultColDef = useMemo(()=>{return {sortable:true}},[])

  const [columnDefs] = useState<any>([
    { field: 'symbol', width: 85, cellRenderer: SymbolRender, sortable: true },

    { field:'profit', headerName: 'Profit', 
      width: 80, 
      cellRenderer: GapRender, 
      cellDataType:'number',
      sortingOrder:['asc','desc'],
      sort:'asc'
    },
    
     {
      field: 'buy_created_date',
      headerName: 'Date',
      width: 110,
      valueFormatter: dateFormatter,
    },

  ]);

  useEffect(() => {
    asyncFetch();
  }, []);

  async function asyncFetch() {
    const positions = await client.getLastPositions();
    const tickers = await client.getTickers();
    const tempObj:any = {};

    tickers.forEach((item:any) => {

      item.markPrice = Number(item.markPrice)

      const findPosition = positions.filter((position:any)=> position.symbol === item.symbol)[0]


      if (!tempObj[item.symbol]) {

        if(findPosition){
          tempObj[item.symbol] = findPosition;
          tempObj[item.symbol].profit = (item.markPrice / findPosition.buy_price - 1) * 100;
          tempObj[item.symbol].markPrice = item.markPrice
        }
      }
     
    });

    const array:any = Object.values(tempObj)

    setRowData(array);
    const needOrder: any = array.filter((item:any) => item.profit < -4);
    setNeedOrderData(needOrder);
   
  }

  const onSelectionChanged = useCallback((event: any) => {
    event.api.getSelectedNodes()[0].data.exchange = exchange;
    setSelectedRowData(event.api.getSelectedNodes()[0].data);
    setNow(new Date());
  }, []);

    const orderList = needOrderData.map((item: any, index) => (
      <span className="text-xs">{item.symbol},</span>
    ));

  return (
    <div className="max-w-full">
      <div className="mx-2 text-xs break-words">
         <div>Order: {orderList}</div> 
      </div>

      <div className="ag-theme-balham-dark w-full" style={{ height: '94vh' }}>
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          // sortingOrder={sortingOrder}
          rowSelection={'single'}
          onRowClicked={onSelectionChanged}
        ></AgGridReact>
      </div>

      <OrderDialog data={selectedRowData} now={now} />
    </div>
  );
}
