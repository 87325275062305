export default function GapRender(props:any) {

 let profit = Number(Number(props.value).toFixed(1))

 profit = profit < 100 ? profit : 0

  return (
    <span className={`text-[0.7rem] ${props.value <= -3 ? 'bg-red-800/50' : ''}`}>
      {profit}
    </span>
  );
}
