export default new (class {
  getOrders = () =>
    new Promise((resolve, reject) => {
      const url = process.env.REACT_APP_API_SERVER + '/coin/binance/tribot/data/charts';

      fetch(url)
        .then((response) => response.json())
        .then((json) => {
          const tempObj = {};
          const tempArr = [];

          json.forEach((item) => {
            if (!tempObj[item.symbol]) {
              tempObj[item.symbol] = { data: [] };
            }
            tempObj[item.symbol].data.push(item);
          });


          resolve(tempObj);
        })
        .catch((error) => {
          console.log('fetch data failed', error);
        });
    });

  getHistory = (symbol) =>
    new Promise((resolve, reject) => {
      const url = process.env.REACT_APP_API_SERVER + '/coin/binance/tribot/histories';

      fetch(url)
        .then((response) => response.json())
        .then((json) => {
          // console.log(json);
          resolve(json);
        })
        .catch((error) => {
          console.log('fetch data failed', error);
        });
    });

  calPriceGap(data, newRow) {
    // console.log('calPriceGap');



    data.sort(function (a, b) {
      try {
        return b.buy_price - a.buy_price;
      } catch (e) {}
    });

    let buyBottom = {};
    let sellTop = {};

    let markPrice = data[data.length - 1].markPrice;


    markPrice = markPrice ? markPrice : 0
   
    for (const [index, item] of data.entries()) {
      if (item.side === 'SELL') {
        sellTop = item;
        buyBottom = data[index - 1];
        break;
      }
    }

    // if (newRow.symbol === 'RNDRUSDT') {
    //   console.log(markPrice, buyBottom);
    // }


    try {
      newRow.longGap = this.toFixed((markPrice / buyBottom.buy_price - 1) * 100, 2);
      newRow.longGapBuyPrice = buyBottom.buy_price;
      newRow.longGapSellPercent = buyBottom.sell_percent;
    } catch (e) {}

    try {
      newRow.shortGap = this.toFixed((sellTop.buy_price / markPrice - 1) * 100, 2);
      newRow.shortGapBuyPrice = sellTop.buy_price;
      newRow.shortGapSellPercent = sellTop.sell_percent;
    } catch (e) {}

    if (newRow.longGap > newRow.shortGap) {
      newRow.plusGap = newRow.longGap;
      newRow.plusPosition = 'LONG';
      newRow.plusBuyPrice = newRow.longGapBuyPrice;
      newRow.plusSellPercent = newRow.longGapSellPercent;
      newRow.plusPositionId = buyBottom ? buyBottom.id : null;
    } else {
      newRow.plusGap = newRow.shortGap;
      newRow.plusPosition = 'SHORT';
      newRow.plusBuyPrice = newRow.shortGapBuyPrice;
      newRow.plusSellPercent = newRow.shortGapSellPercent;
      newRow.plusPositionId = sellTop ? sellTop.id : null;
    }

    newRow.plusPercent = newRow.plusGap / newRow.plusSellPercent;



  }

  getPerpAmt(symbol, amt) {
    if (symbol.includes('PERP')) {
      if (symbol.includes('BTCUSD')) {
        return 100 * amt;
      } else {
        return 10 * amt;
      }
    } else {
      return amt;
    }
  }

  calLiquidation(newRow, item) {
    item.liquidationPrice = Number(item.liquidationPrice);

    if (item.liquidationPrice < item.markPrice) {
      newRow.longLiq = this.toFixed(item.markPrice / item.liquidationPrice, 1);
      if (newRow.longLiq > 7) newRow.longLiq = 7;

      if (item.symbol.includes('PERP')) {
        newRow.shortLiq = this.toFixed(item.markPrice / item.liquidationPrice, 1);
        if (newRow.shortLiq > 7) newRow.shortLiq = 7;
      }
    } else {
      newRow.shortLiq = this.toFixed(item.liquidationPrice / item.markPrice, 1);
    }
  }

  toFixed(num, size) {
    return Number(Number(num).toFixed(size));
  }
})();
