import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Input from '@mui/material/Input';

function calPrice(amt:any, item:any) {

	if (item.symbol.includes('PERP')) {
		return getPerpAmt(amt, item);
	} else {
		return Number((item.markPrice * amt).toFixed(1));
	}
}

function getPerpAmt(amt:any, item:any) {
	if (item.symbol.includes('BTCUSD')) {
		return 100 * amt;
	} else {
		return 10 * amt;
	}
}

export default function NewDialog({ data, side, now }:any) {
	const [open, setOpen] = useState(false);
	const [amt, setAmt] = useState(0);
	const [sellPercent, setSellPercent] = useState(0);


	console.log(data,side)

	useEffect(() => {
		if(data){
			const position = side === 'LONG' ? data.position : data.shortPosition
			const reversePosition = side !== 'LONG' ? data.position : data.shortPosition

			// setPosition(position)
			setAmt( position ? position.amt : reversePosition.amt);
			setSellPercent(position ? position.sell_percent : 3);
			setOpen(true);
		}
	}, [now]);

	const handleSave = (item:any) => {

		let newModel:any = {
			symbol: item.symbol,
			amt: amt,
			sell_percent: sellPercent,
		};

		let url:any = process.env.REACT_APP_API_SERVER;

		if(item.exchange === 'Binance'){
			url += '/coin/tribot/binance/orders'
			newModel.position = side
		}else if(item.exchange === 'Bybit'){
			url += '/coin/tribot/bybit/orders'
			newModel.side = side === 'LONG' ? 'Buy' : 'Sell'
		}

		console.log(newModel)

		fetch(url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(newModel),
		}).then((res) => {
			setOpen(false);
			console.log(res);
		});
	};

	const handleClose = () => {
		setOpen(false);
	};

	const onChangeAmt = (e:any) => {
		setAmt(e.target.value);
	};

	const onChangeSellPercent = (e:any) => {
		setSellPercent(e.target.value);
	};

	return (
		<div>
			{data && (
				<Dialog className="" open={open} onClose={handleClose}>
					<div className="mx-4 mt-2 w-64">{data.symbol} <small>({data.markPrice})</small></div>
					<div className="mx-4 flex items-center">
						<div className="w-24 text-xs">amt</div>
						<Input className="w-20" onChange={onChangeAmt} value={amt} />
						<div className="ml-3 text-xs">${calPrice(amt, data)}</div>
					</div>

					<div className="mx-4 my-1 flex items-center">
						<div className="w-24 text-xs">sell_percent</div>
						<Input className="w-20" onChange={onChangeSellPercent} value={sellPercent} />
					</div>

					<div className="mx-4 mt-1 text-xs flex">
						<div className="w-24 ">position_side</div> {side}
					</div>

					<DialogActions>
						<Button onClick={() => handleSave(data)}>add {side}</Button>
					</DialogActions>
				</Dialog>
			)}
		</div>
	);
}
